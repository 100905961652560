import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import 'antd/dist/reset.css';
import './antd.css';
import { AppSMS } from './App';
import reportWebVitals from './reportWebVitals';
import { API } from 'aws-amplify';
import { AWS_CONFIG } from './config/aws';
import { AppContextProvider } from './contexts/app.context';
import { initializeApolloClient } from './services/graphql/initializeApolloClient';
import { ApolloProvider } from '@apollo/client';

API.configure(AWS_CONFIG);

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
const apolloClient = initializeApolloClient();

const renderSMSauth = <React.StrictMode>
	<ApolloProvider client={apolloClient}>
		<AppContextProvider>
			<AppSMS />
		</AppContextProvider>
	</ApolloProvider>
</React.StrictMode>;

root.render(renderSMSauth);

reportWebVitals();
